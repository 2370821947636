import { useEffect, useState } from 'react';

import { useGetMuriquiProject, useGetProject, useToast } from 'hooks';
import { IDBProject } from 'interfaces';
import { useRouter } from 'next/router';

import PlanContext from './PlanContext';
import { getPlanPeriod } from './PlanContext.helpers';
import { PlanContextProps } from './PlanContext.interfaces';



export function PlanProvider({ children }: PlanContextProps) {
  const { handleToastError } = useToast();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const [kanziProject, setKanziProject] = useState<IDBProject>();
  const [projectId, setProjectId] = useState<string>('');
  const [daysRemaining, setDaysRemaining] = useState<number>();
  const [planPeriod, setPlanPeriod] = useState<string>('');

  const { handleGetMuriquiProject } = useGetMuriquiProject({
    fetchPolicy: 'no-cache',
    onCompleted: ({ project }) => {
      const {
        customerDetail: { subscriptionDate },
      } = project;

      if (subscriptionDate) {
        const { formattedPeriod, remainingDays } = getPlanPeriod(new Date(subscriptionDate));
        setDaysRemaining(remainingDays);
        setPlanPeriod(formattedPeriod);
      }
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleGetProject } = useGetProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ project }) => {
      setKanziProject(project);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!projectId || projectId.trim() === '') return;

    handleGetProject({
      id: String(projectId),
    });

    void handleGetMuriquiProject({
      projectId: String(projectId),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  useEffect(() => {
    if (queryProjectId) {
      setProjectId(queryProjectId as string);
    }
  }, [queryProjectId])

  return (
    <PlanContext.Provider
      value={{
        kanziProject,
        daysRemaining,
        planPeriod,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}
