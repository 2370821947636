import { useContext } from 'react';

import { Icon, PageContainer } from '@netfront/ui-library';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { PlanUsageProps } from './PlanUsage.interfaces';

import { PlanContext } from '../../contexts';

const PlanUsage = ({ plans }: PlanUsageProps) => {
  const { query } = useRouter();
  const { daysRemaining, planPeriod } = useContext(PlanContext);
  const { organisationKey, projectId } = query;

  return (
    <div className="c-plan-usage-container">
      <PageContainer>
        <div className="c-plan-usage-inner">
          <div className="c-plan-usage">
            {plans.map(({ color, label, percentage, usage }) => (
              <div key={`plan-usage-${label}`} className="c-plan-usage__details">
                <CircularProgressbar
                  className="c-progress-circle"
                  strokeWidth={18}
                  styles={buildStyles({
                    pathColor: color,
                    trailColor: 'var( --g-color-grey-100)',
                  })}
                  value={percentage}
                />
                <div className="c-plan-usage__item">
                  <strong className="c-plan-usage__label">{label}</strong>
                  <span className="c-plan-usage__metrics">{usage}</span>
                </div>
              </div>
            ))}
            {planPeriod && (
              <div className="c-plan-usage__details">
                <div className="c-plan-usage__item">
                  <strong className="c-plan-usage__label">Plan period</strong>
                  <span className="c-plan-usage__metrics">{planPeriod} ({daysRemaining} days remaining)</span>
                </div>
              </div>
            )}
            
          </div>

          <div className="c-plan-usage__links">
            <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/payment`}>
              <span 
                className="c-button c-plan-usage__link c-button--cta c-button--xs c-button__text--icon-only" 
                id="change-plan" 
              >
                <span className="c-button__text">
                  <Icon className="c-icon c-button__icon" id="id_payment_icon"/>
                  <span className="c-button__text--hidden">Change plan</span>
                </span>
              </span>
            </Link>
          </div>
        </div>
      </PageContainer>

    </div>
  );
};

export { PlanUsage };
