import { createContext } from 'react';

import { IDBProject } from 'interfaces';

export interface IPlanContext {
  daysRemaining: number | undefined;
  kanziProject: IDBProject | undefined;
  planPeriod: string | undefined;
}

export const PlanContext = createContext<IPlanContext>({
  daysRemaining: undefined,
  kanziProject: undefined,
  planPeriod: undefined,
});

// eslint-disable-next-line import/no-default-export
export default PlanContext;
