import { gql } from '@apollo/client';

const GET_MURIQUI_PROJECT_WITH_DOWNGRADE_REQUEST = gql`
  query getMuriquiProject($projectId: String!) {
    projects {
      get(id: $projectId) {
        customerDetail(product: KANZI) {
          subscriptionDate
          subscriptions {
            downgradeRequest {
              date
              plan {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export { GET_MURIQUI_PROJECT_WITH_DOWNGRADE_REQUEST };
