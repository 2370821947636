import { format } from "date-fns";

// Helper function to get the last day of a given month
const getLastDayOfMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

const getPlanPeriod = (subscriptionDate?: Date): { formattedPeriod: string; remainingDays: number } => {
  if (!subscriptionDate || isNaN(new Date(subscriptionDate).getTime())) {
    return { formattedPeriod: '', remainingDays: 0 };
  }
  const today = new Date();
  const subscriptionDay = new Date(subscriptionDate).getDate();
  const todayDay = today.getDate();
  today.setHours(0, 0, 0, 0);

  let formattedPeriod = '';
  let remainingDays = 0;
  let startDate;
  let endDate;

  if (todayDay >= subscriptionDay) {
    // Create startDate for this month using subscriptionDay
    startDate = new Date(today.getFullYear(), today.getMonth(), subscriptionDay);

    // Create endDate for next month, which is subscriptionDay - 1, but adjust for month length
    const lastDayOfCurrentMonth = getLastDayOfMonth(today.getFullYear(), today.getMonth());
    endDate = new Date(today.getFullYear(), today.getMonth() + 1, Math.min(subscriptionDay - 1, lastDayOfCurrentMonth));

  } else {
    // Create startDate for the previous month (adjust year if necessary)
    const previousMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
    const previousMonthYear = today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear();
    startDate = new Date(previousMonthYear, previousMonth, subscriptionDay);

    // Create endDate for the current month, which is subscriptionDay - 1, adjusted for month length
    const lastDayOfCurrentMonth = getLastDayOfMonth(today.getFullYear(), today.getMonth());
    endDate = new Date(today.getFullYear(), today.getMonth(), Math.min(subscriptionDay - 1, lastDayOfCurrentMonth));
  }

  formattedPeriod = `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`
  remainingDays = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 3600 * 24));

  return { formattedPeriod, remainingDays };
};

export { getPlanPeriod };