import { gql } from '@apollo/client';

const GET_HAS_PARTNER = gql`
  query hasPartner {
    partner {
      hasPartner(product: KANZI)
    }
  }
`;

export { GET_HAS_PARTNER };
