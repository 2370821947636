import { useEffect, useState } from 'react';

import { IUser, useDomain, useIdentitySiteUrls, useUser } from '@netfront/gelada-identity-library';
import { Dropdown, IDropdownNavigationItem, LogoAvatar, UserNavigation, UserNavigationItemProps } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { useHasPartner, useIsMounted, useUserIsAdmin } from '../../hooks';

const UserAccountMenu = () => {

  const { isMounted } = useIsMounted();

  const {
    query: { projectId, organisationKey },
  } = useRouter();


  const { getUser } = useUser();
  const { isDomainReady, getDomain } = useDomain();
  const { isAdmin } = useUserIsAdmin();
  const { getLogoutUrl, getProfileUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [profileUrl, setProfileUrl] = useState<string>();
  const [navigationList, setNavigationList] = useState<UserNavigationItemProps[]>([]);
  const [footerConfig, setFooterConfig] = useState<IDropdownNavigationItem>();
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>();
  const [loggedUser, setLoggedUser] = useState<IUser>();
  const [administrationUrl, setAdministrationUrl] = useState<string>();
  const [partnerUrl, setPartnerUrl] = useState<string>();
  const [hasPartner, setHasPartner] = useState<boolean>(false);

  const { handleHasPartner } = useHasPartner({
    onCompleted(data) {
      const { hasPartner: hasAnyPartner } = data;
      setHasPartner(hasAnyPartner);
    },
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    void handleHasPartner();

    setLoggedUser(getUser());
    setLogoutUrl(getLogoutUrl());
    setProfileUrl(getProfileUrl());
    setAdministrationUrl(`https://partners.${getDomain()}/management/springboard`);
    setPartnerUrl(`https://partners.${getDomain()}/dashboard`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!loggedUser) return;

    const {firstName = '', lastName = '', pictures, email = '' } = loggedUser;

    const { originalPicture, profilePicture } = pictures ?? {};

    const image = !profilePicture?.presignedUrl ? originalPicture?.presignedUrl ?? '' : profilePicture.presignedUrl;

    setProfileImageUrl(String(decodeURIComponent(image)));

    const dropdownNavigationItems: UserNavigationItemProps[] = [
      {
        header: {
          title: `${String(firstName)} ${String(lastName)}`,
          name: String(email),
          iconId: 'id_enter_icon',
          href: String(profileUrl),
          linkText: 'View profile'
        },
        options: [
          {
            iconId: 'id_plans_icon',
            href:  `/dashboard/${String(organisationKey)}/${String(projectId)}/payments`,
            label: 'Manage plan',
            isHidden: !projectId,
          },
          {
            iconId: 'id_integration_icon',
            href: 'https://calendly.com/greg_stephenson',
            label: 'Book demo',
            target: '_blank'
          },
          {
            iconId: 'id_help_docs_icon',
            href: '/docs',
            label: 'Help docs',
          },
          {
            iconId: 'id_partners_icon',
            href: partnerUrl,
            label: 'Partner',
            isHidden: !hasPartner,
          },
          {
            iconId: 'id_users_icon',
            href: administrationUrl,
            label: 'Administration',
            isHidden: !isAdmin,
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    setFooterConfig({
      label: 'Logout',
      href: logoutUrl,
      iconId: 'id_logout_icon'
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationKey, projectId, profileUrl, logoutUrl, loggedUser, hasPartner, isAdmin]);

  return (
    <div className="c-user-account-menu">
      {isMounted && loggedUser && (
        <Dropdown
          dropdownId="profile-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<LogoAvatar avatarImageSrc={String(profileImageUrl)} size="small" title={`${String(loggedUser.firstName)} ${String(loggedUser.lastName)}`} />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <UserNavigation 
            dropdownNavigationItems={navigationList}
            footerConfig={footerConfig} 
          />
        </Dropdown>
      )}
    </div>
  );
};

export { UserAccountMenu };
