import { ToggleSwitch } from '@netfront/ui-library';

import { HighlightListItem } from '../HighlightListItem';

import { ProjectSettingsProps, IProjectSettingField } from './ProjectSettings.interfaces';

const ProjectSettings = ({ onChange, settings }: ProjectSettingsProps) => {
  const {
    hasSpeech = false,
    hasTranslate = false,
    hasVolumeControl = false,
    hasPdf = false,
    isDraggable = false,
    hasAccessibility = false,
  } = settings;

  const handleOnSwitchToggle = ({ field, value }: IProjectSettingField) => {
    onChange({ field, value });
  };

  return (
    <div className="c-project-settings">
      <h2 className="h5 font-body py-2">Settings</h2>
      <ul className="c-project-settings__list">
        <HighlightListItem isHighlighted={hasTranslate}>
          <ToggleSwitch
            id="hasTranslate"
            isChecked={hasTranslate}
            labelText="Translation"
            tooltipPosition="start"
            tooltipText="Enable content translation so your visitors can effortlessly switch between your configured languages, allowing them to understand your content in their preferred language."
            isLabelSideBySide
            onChange={() => handleOnSwitchToggle({ field: 'hasTranslate', value: !hasTranslate })}
          />
        </HighlightListItem>

        <HighlightListItem isHighlighted={hasSpeech}>
          <ToggleSwitch
            id="hasSpeech"
            isChecked={hasSpeech}
            labelText="Speech"
            tooltipPosition="start"
            tooltipText="Enable text-to-speech to accommodate for diverse learning styles, help the visually impaired, and enhance the user experience for everyone."
            isLabelSideBySide
            onChange={() => handleOnSwitchToggle({ field: 'hasSpeech', value: !hasSpeech })}
          />
        </HighlightListItem>

        <HighlightListItem isHighlighted={hasVolumeControl}>
          <ToggleSwitch
            id="hasVolumeControl"
            isChecked={hasVolumeControl}
            labelText="Volume control"
            tooltipPosition="start"
            tooltipText="Enable volume control via the Kanzi website plugin so users can control volume without the need of a keyboard."
            isLabelSideBySide
            onChange={() => handleOnSwitchToggle({ field: 'hasVolumeControl', value: !hasVolumeControl })}
          />
        </HighlightListItem>

        <HighlightListItem isHighlighted={hasPdf}>
          <ToggleSwitch
            id="hasPdf"
            isChecked={hasPdf}
            labelText="Print to pdf"
            tooltipPosition="start"
            tooltipText="Enable Kanzi’s powerful print to PDF feature. A perfectly themed PDF of the current user view will be created on click. This will maintain translated content so visitors can print your content in their preferred language."
            isLabelSideBySide
            onChange={() => handleOnSwitchToggle({ field: 'hasPdf', value: !hasPdf })}
          />
        </HighlightListItem>
        <HighlightListItem isHighlighted={isDraggable}>
          <ToggleSwitch
            id="isDraggable"
            isChecked={isDraggable}
            labelText="Allow drag"
            tooltipPosition="start"
            tooltipText="All your visitors to drag the Kanzi tool around the screen"
            isLabelSideBySide
            onChange={() => handleOnSwitchToggle({ field: 'isDraggable', value: !isDraggable })}
          />
          
        </HighlightListItem>
        <HighlightListItem isHighlighted={hasAccessibility}>
          <ToggleSwitch
            id="hasAccessibility"
            isChecked={hasAccessibility}
            labelText="Accessibility"
            tooltipPosition="start"
            tooltipText="Enable Kanzi powerful accessibility tools"
            isLabelSideBySide
            onChange={() => handleOnSwitchToggle({ field: 'hasAccessibility', value: !hasAccessibility })}
          />
          
        </HighlightListItem>
      </ul>
    </div>
  );
};

export { ProjectSettings };
