import { ChangeEvent, useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { Button, PlanCalculator, PlanCalculatorRow, PlanCalculatorSelect, Select } from '@netfront/ui-library';
import { formatWithThousandsSeparator } from 'utils';

import {
  CURRENCY_OPTIONS,
  DEFAULT_PDF_PLAN_ID,
  DEFAULT_SPEECH_PLAN_ID,
  DEFAULT_TRANSLATE_PLAN_ID,
  PDF_PRODUCT_ID,
  SPEECH_PRODUCT_ID,
  TRANSLATE_PRODUCT_ID,
  UNLIMITED_PDF_PLAN_ID,
  UNLIMITED_SPEECH_PLAN_ID,
  UNLIMITED_TRANSLATE_PLAN_ID,
} from '../ProPlanCard';

import { CurrencySymbol, getPlanById, getPlanOptions, getTotalPrice } from './ProPlanCard.helpers';
import { ProPlanCardProps, ISelectedPlan } from './ProPlanCard.interfaces';

import { CurrencyType } from '../../interfaces';

const ProPlanCard = ({
  fullWidth,
  hasCTA = true,
  hasCurrencySelection = false,
  onPlanChange,
  onUpdateCurrency,
  pdf,
  plans,
  speech,
  title = 'Calculator',
  translate,
}: ProPlanCardProps) => {
  const { isDomainReady } = useDomain();
  const { getRegistrationUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [currency, setCurrency] = useState<CurrencyType>('AUD');
  const [hasUnlimitedPlan, setHasUnlimitedPlan] = useState<boolean>(false);
  const [isPayAsYouGo, setIsPayAsYouGo] = useState<boolean>(false);
  const [registrationUrl, setRegistrationUrl] = useState<string>();

  // Issue is rerendering of plans passed here. Remove state and just have an activePlan prop?
  const [selectedProductPlan, setSelectedProductPlan] = useState<ISelectedPlan>({
    pdf: pdf ?? getPlanById(DEFAULT_PDF_PLAN_ID, plans),
    speech: speech ?? getPlanById(DEFAULT_SPEECH_PLAN_ID, plans),
    translate: translate ?? getPlanById(DEFAULT_TRANSLATE_PLAN_ID, plans),
  });

  const handleSelectChange = ({ target: { id, name, selectedIndex } }: ChangeEvent<HTMLSelectElement>) => {
    const { id: selectedPlanId } = getPlanOptions(plans, Number(id))[selectedIndex - 1];

    setSelectedProductPlan({
      ...selectedProductPlan,
      [name]: getPlanById(Number(selectedPlanId), plans),
    });
  };

  const handleCurrencySelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    const selectedCurrency = value as CurrencyType;

    if (onUpdateCurrency) {
      onUpdateCurrency(selectedCurrency);
    }

    setCurrency(selectedCurrency);
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setRegistrationUrl(getRegistrationUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!isPayAsYouGo) {
      return;
    }

    setSelectedProductPlan({
      pdf: getPlanById(UNLIMITED_PDF_PLAN_ID, plans),
      speech: getPlanById(UNLIMITED_SPEECH_PLAN_ID, plans),
      translate: getPlanById(UNLIMITED_TRANSLATE_PLAN_ID, plans),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPayAsYouGo]);

  useEffect(() => {
    if (!onPlanChange) {
      return;
    }

    const hasUnlimitedPlanSelected =
      selectedProductPlan.pdf?.id === UNLIMITED_PDF_PLAN_ID &&
      selectedProductPlan.speech?.id === UNLIMITED_SPEECH_PLAN_ID &&
      selectedProductPlan.translate?.id === UNLIMITED_TRANSLATE_PLAN_ID;

    setHasUnlimitedPlan(hasUnlimitedPlanSelected);
    setIsPayAsYouGo(hasUnlimitedPlanSelected);

    onPlanChange({
      ...selectedProductPlan,
      totalPrice: hasUnlimitedPlanSelected ? 0 : getTotalPrice(selectedProductPlan),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductPlan]);

  const { pdf: pdfPlan, speech: speechPlan, translate: translatePlan } = selectedProductPlan;

  useEffect(() => {
    setSelectedProductPlan({
      pdf: plans.find(({ id }) => id === selectedProductPlan.pdf?.id),
      speech: plans.find(({ id }) => id === selectedProductPlan.speech?.id),
      translate: plans.find(({ id }) => id === selectedProductPlan.translate?.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  return (
    <>
      {hasCurrencySelection && (
        <div className="c-plan-calculator__select-wrapper">
          <Select
            additionalClassNames="c-plan-calculator__select"
            id="currencySelect"
            labelText={currency}
            name="currencySelect"
            options={CURRENCY_OPTIONS}
            value={currency}
            isLabelHidden
            onChange={handleCurrencySelectChange}
          />
        </div>
      )}

      
      <PlanCalculator
        fullWidth={fullWidth}
        price={
          isPayAsYouGo || hasUnlimitedPlan
            ? 'Starting $0'
            : `${CurrencySymbol[currency]}${formatWithThousandsSeparator(getTotalPrice(selectedProductPlan))}`
        }
        title={title}
      >
        <PlanCalculatorRow>
          {translatePlan && (
            <PlanCalculatorSelect
              id={String(TRANSLATE_PRODUCT_ID)}
              labelText="Translate"
              name="translate"
              options={getPlanOptions(plans, TRANSLATE_PRODUCT_ID)}
              value={translatePlan.price}
              onChange={handleSelectChange}
            />
          )}
        </PlanCalculatorRow>

        <PlanCalculatorRow>
          {speechPlan && (
            <PlanCalculatorSelect
              id={String(SPEECH_PRODUCT_ID)}
              labelText="Speech"
              name="speech"
              options={getPlanOptions(plans, SPEECH_PRODUCT_ID)}
              value={speechPlan.price}
              onChange={handleSelectChange}
            />
          )}
        </PlanCalculatorRow>

        <PlanCalculatorRow>
          {pdfPlan && (
            <PlanCalculatorSelect
              id={String(PDF_PRODUCT_ID)}
              labelText="Pdf"
              name="pdf"
              options={getPlanOptions(plans, PDF_PRODUCT_ID)}
              value={pdfPlan.price}
              onChange={handleSelectChange}
            />
          )}
        </PlanCalculatorRow>

        {hasCTA && (
          <PlanCalculatorRow alignment="center">
            {registrationUrl && <Button linkButton={{ id: 'register', url: registrationUrl }} text="Get started" />}
          </PlanCalculatorRow>
        )}
      </PlanCalculator>
    </>
  );
};

export { ProPlanCard };
