import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
import { useDebouncedCallback } from 'use-debounce';

import { getUpdatedApiKey } from '../apiKey.helpers';

import { UPDATE_API_KEY_MUTATION } from './useUpdateApiKey.graphql';
import {
  IUpdateApiKeyMutationGraphQLResponse,
  IUpdateApiKeyMutationVariables,
  IHandleUpdateApiKeyParams,
  IUseUpdateApiKeyOptions,
  IUseUpdateApiKey,
} from './useUpdateApiKey.interfaces';

import { useKanziMutation } from '../../apollo';

const useUpdateApiKey = (options?: IUseUpdateApiKeyOptions): IUseUpdateApiKey => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateApiKeyOptions);

  const [executeUpdateApiKey, { loading: isLoading }] = useKanziMutation<
    IUpdateApiKeyMutationGraphQLResponse,
    IUpdateApiKeyMutationVariables
  >({
    mutation: mutation ?? UPDATE_API_KEY_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          apiKey: { updateApiKey: apiKey },
        } = data;

        onCompleted({
          apiKey: getUpdatedApiKey(apiKey),
        });
      },
      onError,
    },
    token,
  });

  const debouncedUpdateApiKeyMutation = useDebouncedCallback(
    async ({
      googleMeasurementKey,
      pixelCode,
      id,
      maintainTranslationOnNavigation,
      isAccessibilityMaintainedOnNavigation,
      packageName = null,
      status = null,
      title = null,
      type = null,
    }: IUpdateApiKeyMutationVariables) => {
      await executeUpdateApiKey({
        variables: {
          googleMeasurementKey,
          pixelCode,
          id,
          maintainTranslationOnNavigation,
          isAccessibilityMaintainedOnNavigation,
          packageName,
          status,
          title,
          type,
        },
      });
    },
    API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS,
  );

  const handleUpdateApiKey = async ({
    googleMeasurementKey,
    pixelCode,
    id,
    maintainTranslationOnNavigation,
    isAccessibilityMaintainedOnNavigation,
    packageName = null,
    status = null,
    title = null,
    type = null,
    useDebounce = false,
  }: IHandleUpdateApiKeyParams) => {
    if (useDebounce) {
      await debouncedUpdateApiKeyMutation({
        googleMeasurementKey,
        id,
        pixelCode,
        maintainTranslationOnNavigation,
        isAccessibilityMaintainedOnNavigation,
        packageName,
        status,
        title,
        type,
      });

      return;
    }

    await executeUpdateApiKey({
      variables: {
        googleMeasurementKey,
        pixelCode,
        id,
        maintainTranslationOnNavigation,
        isAccessibilityMaintainedOnNavigation,
        packageName,
        status,
        title,
        type,
      },
    });
  };

  return {
    handleUpdateApiKey,
    isLoading,
  };
};

export { useUpdateApiKey };
